import { auth, db } from "config/firebase";
import {
  DocumentData,
  arrayUnion,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { Activity, ActivityDays } from "types/activity";

const calculateStreak = (activities: DocumentData): number => {
  let streak = 0;
  let date = new Date();
  let dateString = date.toDateString();
  let day = 24 * 60 * 60 * 1000;

  while ((activities[dateString] || []).length > 4) {
    streak++;
    date = new Date(date.getTime() - day);
    dateString = date.toDateString();
  }

  return streak;
};

export const addActivityAndCheckStreak = async (
  activity: Activity
): Promise<number | null> => {
  if (!auth.currentUser) {
    return null;
  }
  const date = new Date();
  const dateString = date.toDateString();

  const userActivityRef = doc(db, "activities", auth.currentUser.uid);
  // first update the user's activities
  await setDoc(
    userActivityRef,
    { [dateString]: arrayUnion(activity) },
    { merge: true }
  );

  // then check if the user has reached 5 activities today
  const docSnap = await getDoc(userActivityRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    const activitiesToday = data[dateString] || [];

    // Check if it's the 5th activity
    if (activitiesToday.length === 5) {
      // Show the celebration screen here
      // how long is there streak?
      return calculateStreak(data); // Indicates that the celebration should be triggered
    }
  }
  return null;
};

export const getActivities = async (): Promise<ActivityDays | null> => {
  if (!auth.currentUser) {
    return null;
  }
  const userActivityRef = doc(db, "activities", auth.currentUser.uid);
  const docSnap = await getDoc(userActivityRef);
  if (docSnap.exists()) {
    return docSnap.data() as ActivityDays;
  }
  return {};
};
