import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getActivities } from "services/firestore/activitiesServices";
import Center from "./utils/Center";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

export const StatisticsDisplay = () => {
  const [todayLeft, setTodayLeft] = useState(0);
  const [streak, setStreak] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getActivities().then((data) => {
      if (data) {
        const today = new Date().toDateString();
        const todayCount = data[today]?.length || 0;
        let streak = 0;

        if (todayCount >= 5) {
          streak = 1;
          setTodayLeft(0);
        } else {
          setTodayLeft(5 - todayCount);
        }
        let day = new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000
        ).toDateString();
        while (data[day] && data[day].length >= 5) {
          streak++;
          day = new Date(
            new Date(day).getTime() - 24 * 60 * 60 * 1000
          ).toDateString();
        }
        setStreak(streak);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Container>
      <Box
        mt={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" align="center">
          Daily Streak
        </Typography>
        <Typography mb={3} variant="h6" mt={2} align="center">
          Complete at least 5 conjugations each day to extend your streak!
        </Typography>
        {loading ? (
          <Center>
            <CircularProgress />
          </Center>
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <RocketLaunchIcon sx={{ fontSize: 150 }} color="secondary" />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                ml={3}
                // mt={-2}
              >
                <Typography fontSize={80} mb={-3} color="secondary">
                  {streak}
                </Typography>
                <Typography variant="h4" color="secondary">
                  day streak!
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1" align="center" mb={2}>
              {todayLeft > 0
                ? `You have ${todayLeft} conjugations left to extend your streak today!`
                : "You've done enough practicing to extend your streak today!"}
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => navigate("/quiz")}
            >
              Back to Quiz
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};
