import emailjs from "@emailjs/browser";
import { Box, Container, FormControl, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserMetadata } from "services/firestore/usersServices";

export const ContactForm = () => {
  const [feedback, setFeedback] = useState("");
  const [metadata, setMetadata] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserMetadata().then((data) => {
      if (data) {
        setMetadata(data);
      }
    });
  });

  function handleFormChange(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    setFeedback(event.target.value);
  }

  const sendEmail = () => {
    emailjs
      .send(
        "service_s379reh",
        "template_d2x0svd",
        { feedback, ...metadata },
        {
          publicKey: "JUqKsyIOw4X0oJgG_",
        }
      )
      .then(
        () => {
          console.log("email sent!");
        },
        (error) => {
          console.log("email failed to send...", error.text);
        }
      );
    setEmailSent(true);
  };

  return (
    <Container>
      <Box
        mt={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" align="center">
          Contact Us
        </Typography>
        <Typography mb={3} variant="body1" align="center">
          If you have any questions, suggestions or feedback, please let us
          know!
        </Typography>

        {emailSent && (
          <Box
            mt={5}
            justifyContent="center"
            flexDirection="column"
            display="flex"
          >
            <Typography mb={3} variant="h6" align="center">
              Thank you for your feedback!
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => navigate("/quiz")}
            >
              Back to Quiz
            </Button>
          </Box>
        )}
        {!emailSent && (
          <FormControl>
            <TextField
              sx={{ minWidth: 400, mb: 3 }}
              multiline={true}
              rows={4}
              fullWidth={true}
              onChange={handleFormChange}
            />
            <Button size="large" variant="contained" onClick={sendEmail}>
              Submit
            </Button>
          </FormControl>
        )}
      </Box>
    </Container>
  );
};
