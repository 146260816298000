import Lookup from "components/Lookup";
import Layout from "components/layout/Layout";

const LookupScreen = () => {
  return (
    <Layout>
      <Lookup />
    </Layout>
  );
};

export default LookupScreen;
