import { useEffect } from "react";
import MistakesQuiz from "components/MistakesQuiz";
import Layout from "components/layout/Layout";

const MistakesScreen = () => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <MistakesQuiz />
    </Layout>
  );
};

export default MistakesScreen;
