import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#3C5E95",
    },
    secondary: {
      main: "#DC7830",
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
