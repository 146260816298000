import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { TENSES } from "lib/tenseTranslator";
import React, { useEffect, useState } from "react";
import { getVerb, getVerbs } from "services/firestore/verbsServices";
import { Tense, Verb, VerbSummary } from "types/verb";
import LookupCard from "./LookupCard";
import Center from "./utils/Center";

const Lookup: React.FC = () => {
  const [verbs, setVerbs] = useState<VerbSummary[]>([]);
  const [selectedVerbId, setSelectedVerbId] = useState<number | null>(null);
  const [verbDetails, setVerbDetails] = useState<Verb | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVerbs = async () => {
      const fetchedVerbs = await getVerbs();
      setVerbs(fetchedVerbs);
    };
    setLoading(true);
    fetchVerbs().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchVerbDetails = async () => {
      if (selectedVerbId) {
        const fetchedVerbDetails = await getVerb(selectedVerbId);
        setVerbDetails(fetchedVerbDetails);
      } else {
        setVerbDetails(null);
      }
    };

    fetchVerbDetails();
  }, [selectedVerbId]);

  const handleVerbChange = (
    event: React.SyntheticEvent,
    value: VerbSummary | null
  ) => {
    setSelectedVerbId(value?.id || null);
  };

  return (
    <Container sx={{ minWidth: 275 }}>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <>
          <Box
            mt={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Autocomplete
              sx={{ width: 300 }}
              options={verbs}
              getOptionLabel={(verb) => verb.name}
              onChange={handleVerbChange}
              renderInput={(params) => <TextField {...params} label="Verb" />}
            />
            {verbDetails?.translation && (
              <>
                <Typography variant="h4" mt={2}>
                  {verbDetails.verb}
                </Typography>
                <Typography
                  variant="h6"
                  mt={2}
                  fontStyle={"italic"}
                  color="text.secondary"
                >
                  "{verbDetails.translation}"
                </Typography>
              </>
            )}
          </Box>

          {verbDetails && (
            <Grid mt={3} container spacing={2}>
              {TENSES.map((tense) => {
                const [key, display] = tense;
                return (
                  <Grid item xs={12} md={6}>
                    <LookupCard
                      key={key}
                      tense={key}
                      display={display}
                      pronouns={verbDetails.conjugations[key as Tense]}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )}
    </Container>
  );
};

export default Lookup;
