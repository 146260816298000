import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Fab } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VerbSelector from "components/VerbSelector";
import Layout from "components/layout/Layout";

const Home = () => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  const navigate = useNavigate();

  return (
    <Layout>
      <VerbSelector />
      <Fab
        variant="extended"
        color="primary"
        onClick={() => navigate("/quiz")}
        sx={{
          position: "fixed",
          bottom: 16,
          right: "50%",
          transform: "translateX(50%)",
        }}
      >
        <RocketLaunchIcon sx={{ mr: 1 }} />
        Vamos Lá!
      </Fab>
    </Layout>
  );
};

export default Home;
