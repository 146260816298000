import { useEffect } from "react";
import Quiz from "components/Quiz";
import Layout from "components/layout/Layout";

const QuizScreen = () => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Quiz />
    </Layout>
  );
};

export default QuizScreen;
