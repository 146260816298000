import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import "./Streak.css";
import { useEffect, useState } from "react";
import { Backdrop, Box, Typography } from "@mui/material";

interface Props {
  days: number;
}

export default function Streak({ days }: Props) {
  const { width, height } = useWindowSize();
  const [showText, setShowText] = useState("spin");
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowText("fadeOut"); // Update state to trigger fade out
    }, 2000);

    setTimeout(() => {
      setShowText("end");
      setOpen(false);
    }, 5000);
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Confetti width={width} height={height} />
      {showText !== "end" && (
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              fontSize={200}
              className={`animated-text ${
                showText === "fadeOut" ? "" : "spin"
              }`}
            >
              {days}
            </Typography>
            <Typography variant="h1">Day Streak!</Typography>
          </Box>
        </>
      )}
    </Backdrop>
  );
}
