import { Pronoun } from "types/verb";

export const formatSentence = (
  sentence: string,
  conjugation: string,
  pronoun: Pronoun
): string => {
  let stringToFind = `(?<=[\\s.,!?]|^)${conjugation}(?=[\\s.,!?]|$)`; // eslint-disable-line
  let formatted = sentence.replace(
    new RegExp(stringToFind, "gi"),
    "__________"
  );
  if (!formatted.toLowerCase().includes(pronoun.toLowerCase())) {
    formatted = formatted + ` (${pronoun})`;
  }

  return formatted;
};
