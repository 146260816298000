import { doc, getDoc } from "@firebase/firestore";
import { auth, db } from "config/firebase";

export const getUserPreferences = async () => {
  const user = auth.currentUser;

  if (user) {
    const docRef = doc(db, `users/${user.uid}`);
    const userDoc = await getDoc(docRef);
    if (!userDoc.exists()) {
      throw new Error("User not found");
    } else {
      return (
        userDoc.data().settings || { numberOfVerbs: 10, tenses: ["Presente"] }
      );
    }
  }
};

export const getUserMetadata = async () => {
  const user = auth.currentUser;

  if (user) {
    const docRef = doc(db, `users/${user.uid}`);
    const userDoc = await getDoc(docRef);
    if (!userDoc.exists()) {
      throw new Error("User not found");
    } else {
      const { displayName, email } = userDoc.data();
      return { displayName, email };
    }
  }
};
