import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "config/firebase";
import { TENSES, displayTenseInPortuguese } from "lib/tenseTranslator";

const VerbSelector = () => {
  const [selectedTenses, setSelectedTenses] = useState<string[]>([]);
  const [number, setNumber] = useState("10");
  const user = auth.currentUser;

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = async () => {
    if (user) {
      const docRef = doc(db, `users/${user.uid}`);
      const userDoc = await getDoc(docRef);
      if (userDoc.exists()) {
        console.log("Document data:", userDoc.data());
        setSelectedTenses(userDoc.data().settings.tenses || []);
        setNumber(userDoc.data().settings.numberOfVerbs || 10);
        console.log(selectedTenses);
      }
    }
  };

  const handleTenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user) {
      const { value, checked } = event.target;
      const updatedTenses = checked
        ? [...selectedTenses, value]
        : selectedTenses.filter((tense) => tense !== value);

      setSelectedTenses(updatedTenses);

      // Update Firestore
      const docRef = doc(db, `users/${user.uid}`);
      updateDoc(docRef, {
        settings: { tenses: updatedTenses },
      });
    }
  };

  const handleNumberChange = (event: SelectChangeEvent<string>) => {
    if (user) {
      setNumber(event.target.value);

      // Update Firestore
      const docRef = doc(db, `users/${user.uid}`);
      updateDoc(docRef, {
        "settings.numberOfVerbs": event.target.value,
      });
    }
  };

  return (
    <Container>
      <Box pt={2}>
        <Grid container spacing={2} alignItems={"stretch"}>
          <Grid item xs={12} md={12}>
            <Paper elevation={0} sx={{ height: "100%" }}>
              <Box
                p={2}
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" component="div">
                  You're one step closer to mastering Portuguese verbs!
                </Typography>
                <Typography mt={2} variant="body1" component="div">
                  Select which verbs and tenses you want to practice and get
                  started!
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper elevation={0} sx={{ height: "100%" }}>
              <Box p={2}>
                <Typography mb={1.25} variant="h5" component="div">
                  1. Verb Selection
                </Typography>
                <FormControl fullWidth>
                  <FormLabel component="legend">
                    Which verbs do you want to practice?
                  </FormLabel>
                  <Select
                    labelId="number"
                    id="number-select"
                    value={number}
                    onChange={handleNumberChange}
                  >
                    <MenuItem value={10}>10 Most Common</MenuItem>
                    <MenuItem value={25}>25 Most Common</MenuItem>
                    <MenuItem value={50}>50 Most Common</MenuItem>
                    <MenuItem value={100}>100 Most Common</MenuItem>
                    <MenuItem value={250}>250 Most Common</MenuItem>
                    <MenuItem value={500}>500 Most Common</MenuItem>
                    <MenuItem value={1000}>1000 Most Common</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <Box p={2}>
                <Typography mb={1.25} variant="h5" component="div">
                  2. Tense Selection
                </Typography>
                <FormControl
                  sx={{ mb: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">
                    Which verb tenses do you want to practice? (Tip: they're
                    ordered from simplest to most advanced)
                  </FormLabel>
                  <FormGroup>
                    <Grid mt={1} container spacing={2}>
                      {TENSES.map((tense) => {
                        const [key, display, example] = tense;
                        return (
                          <Grid item xs={12} md={6} lg={4} key={key}>
                            <Card elevation={3}>
                              <CardContent>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  {display}
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={selectedTenses.includes(key)}
                                      onChange={handleTenseChange}
                                      value={key}
                                    />
                                  }
                                  label={
                                    <Typography variant="h6">
                                      {displayTenseInPortuguese(key)}
                                    </Typography>
                                  }
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {example}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </FormGroup>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default VerbSelector;
