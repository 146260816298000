import { StatisticsDisplay } from "components/StatisticsDisplay";
import Layout from "components/layout/Layout";

const StatisticsScreen = () => {
  return (
    <Layout>
      <StatisticsDisplay />
    </Layout>
  );
};

export default StatisticsScreen;
