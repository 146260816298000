import { useEffect } from "react";
import { ContactForm } from "components/ContactForm";
import Layout from "components/layout/Layout";

const FeedbackScreen = () => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <ContactForm />
    </Layout>
  );
};

export default FeedbackScreen;
