import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AuthContainer from "components/auth/AuthContainer";
import Center from "components/utils/Center";

const Login = () => {
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <Center>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              component="div"
              align={smallScreen ? "center" : "inherit"}
            >
              Welcome to Verbs.pt!
            </Typography>
            <Typography
              variant="h6"
              component="div"
              align={smallScreen ? "center" : "inherit"}
            >
              The best place to practice conjugating Portuguese Verbs
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} mt={smallScreen ? 4 : 0}>
            <AuthContainer />
          </Grid>
        </Grid>
      </Center>
    </Container>
  );
};

export default Login;
