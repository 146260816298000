import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

import { firebaseConfig } from "config/firebase.config";
import {
  getAuth,
  GoogleAuthProvider,
  connectAuthEmulator,
} from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

export const Firebase = initializeApp(firebaseConfig);
export const auth = getAuth();
export const Providers = { google: new GoogleAuthProvider() };
export const db = getFirestore(Firebase);
export const storage = getStorage();
export const functions = getFunctions();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  if (
    !process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST ||
    !process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_HOST ||
    !process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT
  ) {
    throw new Error(
      "You must set the REACT_APP_FIREBASE_AUTH_EMULATOR_HOST, REACT_APP_FIREBASE_FIRESTORE_EMULATOR_HOST and REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT environment variables when running the app locally."
    );
  }

  // Point the emulator to the local Firestore emulator
  connectAuthEmulator(
    auth,
    `http://${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}`
  );
  connectFirestoreEmulator(
    db,
    `${process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_HOST}`,
    parseInt(process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT)
  );

  connectStorageEmulator(storage, "localhost", 9199);
  if (
    !process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST ||
    !process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT
  ) {
    throw new Error(
      "You must set the REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST and REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT environment variables when running the app locally."
    );
  }
  connectFunctionsEmulator(
    functions,
    process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT)
  );
}
