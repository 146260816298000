import { signOut } from "@firebase/auth";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "config/firebase";
import LogoSvgIcon from "./LogoSvgIcon";
import { Button, Container, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function ResponsiveAppBar() {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();

    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            onClick={() => navigate("/")}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}
          >
            <LogoSvgIcon />
          </IconButton>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => navigate("/")}>Home</MenuItem>
              <MenuItem onClick={() => navigate("/mistakes")}>
                Review Mistakes
              </MenuItem>
              <MenuItem onClick={() => navigate("/lookup")}>
                Verb Lookup
              </MenuItem>
              <MenuItem onClick={() => navigate("/stats")}>
                Daily Streak
              </MenuItem>
            </Menu>
          </Box>
          <IconButton
            onClick={() => navigate("/")}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, mr: 0 }}
          >
            <LogoSvgIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              key={"home"}
              onClick={() => navigate("/")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Home
            </Button>
            <Button
              key={"review"}
              onClick={() => navigate("/mistakes")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Review Mistakes
            </Button>
            <Button
              key={"review"}
              onClick={() => navigate("/lookup")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Verb Lookup
            </Button>
            <Button
              key={"stats"}
              onClick={() => navigate("/stats")}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              Daily Streak
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
            >
              <Avatar alt="Avatar" src={user?.photoURL || ""} />
            </IconButton>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem key="settings" onClick={() => navigate("/settings")}>
                <Typography textAlign="center">Settings</Typography>
              </MenuItem> */}
              <MenuItem key="contact" onClick={() => navigate("/contact")}>
                Leave Feedback
              </MenuItem>
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
