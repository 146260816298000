export const TENSES: string[][] = [
  ["Presente", "Present", "Eu leio um livro."],
  ["Pretérito Perfeito", "Simple Past", "Eu li um livro."],
  ["Pretérito Imperfeito", "Imperfect Past", "Eu lia um livro."],
  ["Futuro do Presente", "Future", "Eu lerei um livro."],
  ["Imperativo Afirmativo", "Imperative", "Leia um livro!"],
  ["Imperativo Negativo", "Negative Imperative", "Não leia um livro!"],
  ["Futuro do Pretérito", "Conditional", "Eu leria um livro."],
  ["Infinitivo Pessoal", "Personal Infinitive", "Para ler um livro."],
  [
    "Subjuntivo Presente",
    "Present Subjunctive",
    "Eu quero que eu leia um livro.",
  ],
];

export const displayTenseInPortuguese = (tense: string): string => {
  switch (tense) {
    case "Futuro do Presente":
      return "Futuro";
    case "Futuro do Pretérito":
      return "Condicional";
    case "Subjuntivo Presente":
      return "Presente do Conjuntivo";
    default:
      return tense;
  }
};
