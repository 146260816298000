import { httpsCallable } from "firebase/functions";
import { functions } from "config/firebase";
import { Pronoun } from "types/verb";

export interface SampleSentence {
  pt: string;
  english: string;
}

export const getSampleSentence = async (
  verb: string,
  tense: string,
  pronoun: Pronoun,
  conjugation: string
): Promise<SampleSentence> => {
  const generateSamplePrompt = httpsCallable(functions, "generateSamplePrompt");
  const data = { verb, tense, pronoun, conjugation };

  const response = await generateSamplePrompt(data);
  const responseData: any = response.data;

  if ("text" in responseData) {
    return JSON.parse(
      (responseData as { text: string }).text
    ) as SampleSentence;
  }

  // For some reason when run in the emulator the response is an object
  return responseData as SampleSentence;
};

export const getSentenceAudio = async (sentence: string): Promise<string> => {
  const generateAudio = httpsCallable(functions, "generateAudio");
  const data = { sentence };

  const response = await generateAudio(data);
  const responseData: any = response.data;
  return responseData.audioUri;
};
