import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Pronoun, Tense, Verb } from "types/verb";
import Center from "./utils/Center";
import { getUserPreferences } from "services/firestore/usersServices";
import { getVerb } from "services/firestore/verbsServices";
import { Mistake } from "types/mistake";
import { Timestamp } from "firebase/firestore";
import { addMistake } from "services/firestore/mistakesServices";
import { auth } from "config/firebase";
import SentenceBox from "./SentenceBox";

const PRONOUNS: Pronoun[] = [
  "eu",
  "tu",
  "ele",
  "ela",
  "você",
  "nós",
  "eles",
  "elas",
  "vocês",
];

const IMPERATIVE_PRONOUNS: Pronoun[] = ["tu", "você", "vocês"];

const Quiz = () => {
  const user = auth.currentUser;
  let [verb, setVerb] = useState<Verb>();
  const [loading, setLoading] = useState(true);
  const [validate, setValidate] = useState(false);
  const [tense, setTense] = useState<Tense>("Presente");
  const [pronoun, setPronoun] = useState<Pronoun>("eu");

  const loadData = async () => {
    setLoading(true);

    setValidate(false);

    const userSettings = await getUserPreferences();
    const tn =
      userSettings.tenses[
        Math.floor(Math.random() * userSettings.tenses.length)
      ];
    setTense(tn);
    const numberOfVerbs = userSettings.numberOfVerbs || 10;
    const randomIndex =
      1 + Math.floor(Math.random() * (parseInt(numberOfVerbs) + 1));

    const v = await getVerb(randomIndex);
    setVerb(v);
    if (!v) {
      console.log("No verb set");
      return;
    }

    let pronouns: Pronoun[] =
      tn === "Imperativo Afirmativo" || tn === "Imperativo Negativo"
        ? IMPERATIVE_PRONOUNS
        : PRONOUNS;

    setPronoun(pronouns[Math.floor(Math.random() * pronouns.length)]);

    setLoading(false);
  };

  const handleMistake = () => {
    if (!verb || !user) return;
    const mistake: Mistake = {
      verbId: verb.id,
      tense: tense,
      pronoun: pronoun,
      timestamp: Timestamp.now(),
    };
    addMistake(user.uid, mistake);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container sx={{ minWidth: 275 }}>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        verb && (
          <Box mt={3}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid key={verb.id} item xs={12} sm={9} md={6}>
                <SentenceBox
                  verb={verb}
                  tense={tense}
                  pronoun={pronoun}
                  conjugation={verb.conjugations[tense][pronoun] || ""}
                  validate={validate}
                  onInvalid={handleMistake}
                />
              </Grid>
            </Grid>
            <Box mt={5} justifyContent="center" display="flex">
              {validate ? (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={loadData}
                >
                  Next Verb!
                </Button>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setValidate(true)}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        )
      )}
    </Container>
  );
};

export default Quiz;
