import { Container, ThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import ResponsiveAppBar from "components/utils/ResponsiveAppBar";
import theme from "config/theme";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ResponsiveAppBar />
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
