import { storage } from "config/firebase";
import { formatSentence } from "lib/sentenceFormatter";
import { TENSES, displayTenseInPortuguese } from "lib/tenseTranslator";
import { Pronoun, Tense, Verb } from "types/verb";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { getSampleSentence, getSentenceAudio } from "services/sentenceService";
import { addActivityAndCheckStreak } from "services/firestore/activitiesServices";
import { Timestamp } from "firebase/firestore";
import Streak from "./Streak";
import { compareVerbs } from "lib/verbComparer";

interface Props {
  verb: Verb;
  tense: Tense;
  pronoun: Pronoun;
  conjugation: string;
  validate: boolean;
  onValid?: () => void;
  onInvalid?: () => void;
}

const SentenceBox = ({
  verb,
  tense,
  pronoun,
  conjugation,
  validate,
  onValid,
  onInvalid,
}: Props) => {
  // console.log("SentenceBox", verb.verb, tense, pronoun, conjugation);

  const [userConjugation, setUserConjugation] = useState("");
  const [hint, setHint] = useState(false);
  const [sentence, setSentence] = useState<string>("");
  const [translation, setTranslation] = useState<string>("");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [streak, setStreak] = useState<number | null>(null);

  function handleFormChange(event: {
    target: { value: React.SetStateAction<string> };
  }) {
    setUserConjugation(event.target.value);
  }

  const onHint = () => {
    setHint(true);
  };

  const onAudio = async () => {
    const audioRef = ref(storage, audioUrl);
    const uri = await getDownloadURL(audioRef);

    console.log("uri:", uri);

    // The rest of this plays the audio
    try {
      const soundObject = new Audio(uri);
      soundObject.play();
    } catch (error) {
      console.log("error:", error);
    }
  };

  const tenseTooltip = (tense: Tense): string => {
    const found = TENSES.find((t) => t[0] === tense);
    if (!found) return "";
    return `${found[1]}. Example: ${found[2]}`;
  };

  useEffect(() => {
    setUserConjugation("");
    setHint(false);
  }, [verb]);

  useEffect(() => {
    if (validate) {
      const success = compareVerbs(userConjugation, conjugation);
      if (success) {
        onValid && onValid();
      } else {
        onInvalid && onInvalid();
      }
      addActivityAndCheckStreak({
        verbId: verb.id,
        success,
        tense,
        pronoun,
        timestamp: Timestamp.now(),
      }).then((streak) => {
        setStreak(streak);
        if (streak) {
          setTimeout(() => setStreak(null), 5000);
        }
      });
    }
  }, [validate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSampleSentence(
      verb.verb,
      displayTenseInPortuguese(tense),
      pronoun,
      conjugation
    ).then((sampleSentence): void => {
      setSentence(formatSentence(sampleSentence.pt, conjugation, pronoun));
      setTranslation(sampleSentence.english);

      // kickoff the audio
      getSentenceAudio(sampleSentence.pt).then((audioUrl) => {
        setAudioUrl(audioUrl);
      });
    });
  }, [verb]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Box
            mb={3}
            display="flex"
            justifyContent="space-between"
            alignItems={"flex-start"}
          >
            <Box mr={2}>
              <Typography
                color="white"
                bgcolor="secondary.light"
                p={1}
                variant="button"
              >
                Verb
              </Typography>
              <Typography variant="h4" component="div">
                {verb.verb}
              </Typography>
            </Box>
            <Box>
              <Box pt={0}>
                <Typography
                  color="white"
                  bgcolor="secondary.light"
                  p={1}
                  variant="button"
                >
                  Tense
                </Typography>
                <Tooltip title={tenseTooltip(tense)}>
                  <IconButton sx={{ pl: 1, pt: 0, pb: 0, pr: 0 }} size="small">
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Typography variant="h4" component="div">
                {displayTenseInPortuguese(tense).toLowerCase()}
              </Typography>
            </Box>
          </Box>
          {hint && (
            <Box mb={3}>
              <Typography
                color="white"
                bgcolor="secondary.light"
                p={1}
                variant="button"
              >
                Translation
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontStyle: "italic" }}
                component="div"
                color={"text.primary"}
              >
                {translation}
              </Typography>
            </Box>
          )}
          <Divider />
          <Typography
            variant="h6"
            sx={{ mb: 1.5, mt: 3 }}
            color="text.secondary"
          >
            {sentence}
          </Typography>
          <TextField
            id="conjugation"
            fullWidth
            sx={{ mb: 2 }}
            onChange={handleFormChange}
            value={userConjugation}
          />
          {validate &&
            (compareVerbs(userConjugation, conjugation) ? (
              <Alert severity="success">Great Job! You got it right!</Alert>
            ) : (
              <Alert severity="error">
                Nice try. The correct answer is {conjugation}
              </Alert>
            ))}
        </CardContent>

        <CardActions>
          <Grid container justifyContent="space-between">
            <Button
              disabled={hint}
              startIcon={<TranslateIcon />}
              size="small"
              onClick={onHint}
            >
              Translate
            </Button>
            {audioUrl && (
              <Button
                startIcon={<HeadphonesIcon />}
                size="small"
                onClick={onAudio}
              >
                Listen
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
      {streak && <Streak days={streak} />}
    </>
  );
};

export default SentenceBox;
