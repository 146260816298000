import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { auth } from "config/firebase";
import {
  clearMistakes,
  getMistakes,
  removeMistake,
} from "services/firestore/mistakesServices";
import { getVerb } from "services/firestore/verbsServices";
import { Mistake } from "types/mistake";
import { Pronoun, Tense, Verb } from "types/verb";
import SentenceBox from "./SentenceBox";
import Center from "./utils/Center";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const MistakesQuiz = () => {
  const user = auth.currentUser;
  const navigate = useNavigate();

  let [verb, setVerb] = useState<Verb>();
  const [loading, setLoading] = useState(true);
  const [validate, setValidate] = useState(false);
  const [tense, setTense] = useState<Tense>("Presente");
  const [pronoun, setPronoun] = useState<Pronoun>("eu");
  const [mistakes, setMistakes] = useState<Mistake[]>([]);
  const [currentMistake, setCurrentMistake] = useState(0);

  const nextMistake = async () => {
    if (currentMistake + 1 < mistakes.length) {
      setCurrentMistake(currentMistake + 1);
      const v = await getVerb(mistakes[currentMistake + 1].verbId);
      if (!v) {
        console.log("No verb set");
        return;
      }
      setVerb(v);
      setTense(mistakes[currentMistake + 1].tense);
      setPronoun(mistakes[currentMistake + 1].pronoun);
      setValidate(false);
    }
  };

  const handleCorrect = () => {
    if (!user) return;

    const mistake = mistakes[currentMistake];
    removeMistake(user.uid, mistake.timestamp);
  };

  const handleClearMistakes = () => {
    if (!user) return;
    clearMistakes(user.uid);

    setMistakes([]);
    setVerb(undefined);
  };

  const loadData = async () => {
    setLoading(true);

    setValidate(false);

    const mistakes = await getMistakes(user?.uid || "1");
    setMistakes(mistakes);

    if (mistakes.length === 0) {
      console.log("No mistakes");
      setLoading(false);
      return;
    }

    setCurrentMistake(0);
    const v = await getVerb(mistakes[0].verbId);
    setVerb(v);
    if (!v) {
      console.log("No verb set");
      return;
    }
    setTense(mistakes[0].tense);
    setPronoun(mistakes[0].pronoun);

    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mistakeHeader = () => {
    if (mistakes.length === 0) {
      return "No mistakes to review";
    }
    return `Mistake ${currentMistake + 1} of ${mistakes.length}`;
  };

  return (
    <Container sx={{ minWidth: 275 }}>
      {loading ? (
        <Center>
          <CircularProgress />
        </Center>
      ) : (
        <Box mt={3}>
          <Box
            mb={3}
            display={"flex"}
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h4" align="center">
              {"Mistakes Review"}
            </Typography>
            <Typography variant="h6" align="center">
              {mistakeHeader()}
            </Typography>
            {mistakes.length > 0 && (
              <Button
                size="small"
                // variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={handleClearMistakes}
              >
                Clear All Mistakes
              </Button>
            )}
          </Box>
          {!verb && (
            <Box mt={5} justifyContent="center" display="flex">
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate("/quiz")}
              >
                Back to Quiz
              </Button>
            </Box>
          )}
          {verb && (
            <>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid key={verb.id} item xs={12} sm={9} md={6}>
                  <SentenceBox
                    verb={verb}
                    tense={tense}
                    pronoun={pronoun}
                    conjugation={verb.conjugations[tense][pronoun] || ""}
                    validate={validate}
                    onValid={handleCorrect}
                  />
                </Grid>
              </Grid>
              <Box mt={5} justifyContent="center" display="flex">
                {validate ? (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={nextMistake}
                  >
                    Next Verb!
                  </Button>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => setValidate(true)}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default MistakesQuiz;
