import { useState } from "react";
import { signInWithPopup, User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, Providers } from "config/firebase";
import { Button, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Center from "components/utils/Center";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

interface Props {}

const AuthContainer = (props: Props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const createUser = async (user: User) => {
    console.info("TODO: create user in firestore");
    const docRef = doc(db, `users/${user.uid}`);

    const userDoc = await getDoc(docRef);

    if (!userDoc.exists()) {
      const { uid, photoURL, displayName, email } = user;

      setDoc(docRef, {
        uid,
        displayName,
        email,
        photoURL,
        settings: {
          tenses: ["Presente"],
          numberOfVerbs: 10,
        },
      })
        .then(() => {
          console.info("user created");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  const signInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then((result) => {
        setDisabled(false);
        createUser(result.user);
        console.info("TODO: navigate to authenticated screen");
        navigate("/");
      })
      .catch((error) => {
        setErrorMessage(error.code + ": " + error.message);
        setDisabled(false);
      });
  };

  return (
    <Center height={"auto"}>
      <Button
        startIcon={<GoogleIcon />}
        size="large"
        disabled={disabled}
        variant="contained"
        onClick={signInWithGoogle}
      >
        Sign In With Google
      </Button>
      <Typography sx={{ mt: 2 }} color={"red"}>
        {errorMessage}
      </Typography>
    </Center>
  );
};

export default AuthContainer;
