import { doc, getDoc, getDocs, collection } from "@firebase/firestore";

import { db } from "config/firebase";
import { Verb, VerbSummary } from "../../types/verb";

export const getVerb = async (verbNumber: number): Promise<Verb> => {
  const docRef = doc(db, `verbs/${verbNumber}`);
  console.log("getting verb", verbNumber);

  const verbDoc = await getDoc(docRef);
  console.log(verbDoc.data());

  const data = verbDoc.data();
  if (!data) {
    throw new Error("No verb found");
  }
  return {
    id: parseInt(data.id),
    verb: data.verb,
    translation: data.translation,
    example: data.example,
    conjugations: data.conjugations,
    prompts: data.prompts,
    regular: data.regular,
  };
};

export const getVerbs = async (): Promise<VerbSummary[]> => {
  const verbSnapshots: VerbSummary[] = [];
  const snapshot = await getDocs(collection(db, "verbs"));
  snapshot.forEach((doc) => {
    verbSnapshots.push({ id: parseInt(doc.id), name: doc.data().verb });
  });
  verbSnapshots.sort((a, b) => (a.name < b.name ? -1 : 1));
  return verbSnapshots;
};
