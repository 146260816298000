import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { Mistake } from "../../types/mistake";
import { Timestamp } from "firebase/firestore";

export const addMistake = async (
  userId: string,
  newMistake: Mistake
): Promise<void> => {
  const userMistakesRef = doc(db, "mistakes", userId);
  const docSnap = await getDoc(userMistakesRef);

  if (docSnap.exists()) {
    // If the document exists, add the new mistake to the existing list
    let mistakes: Mistake[] = docSnap.data().recentMistakes || [];
    mistakes.push(newMistake); // Add the new mistake

    // Ensure the array is sorted by timestamp in ascending order and limit to the most recent 25 entries
    mistakes = mistakes
      .sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
      .slice(0, 25);

    // Update the document with the new list of mistakes
    await updateDoc(userMistakesRef, {
      recentMistakes: mistakes,
    });
  } else {
    // If the document does not exist, create it with the new mistake as the first entry
    await setDoc(userMistakesRef, {
      recentMistakes: [newMistake],
    });
  }
};

export const removeMistake = async (userId: string, timestamp: Timestamp) => {
  const userMistakesRef = doc(db, "mistakes", userId);
  const docSnap = await getDoc(userMistakesRef);

  if (docSnap.exists()) {
    let mistakes: Mistake[] = docSnap.data().recentMistakes || [];

    mistakes = mistakes.filter(
      (mistake) => mistake.timestamp.seconds !== timestamp.seconds
    );

    await updateDoc(userMistakesRef, {
      recentMistakes: mistakes,
    });
  }
};

export const getMistakes = async (userId: string): Promise<Mistake[]> => {
  const userMistakesRef = doc(db, "mistakes", userId);
  const docSnap = await getDoc(userMistakesRef);

  if (docSnap.exists()) {
    return docSnap.data().recentMistakes;
  } else {
    return [];
  }
};

export const clearMistakes = async (userId: string) => {
  const userMistakesRef = doc(db, "mistakes", userId);
  await setDoc(userMistakesRef, {
    recentMistakes: [],
  });
};
