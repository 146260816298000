import Home from "screens/Home";
import Login from "screens/Login";
import MistakesScreen from "screens/MistakesScreen";
import QuizScreen from "screens/QuizScreen";
import FeedbackScreen from "screens/FeedbackScreen";
import LookupScreen from "screens/LookupScreen";
import StatisticsScreen from "screens/StatisticsScreen";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    protected: true,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },

  {
    path: "/quiz",
    component: QuizScreen,
    name: "Quiz Screen",
    protected: true,
  },
  {
    path: "/mistakes",
    component: MistakesScreen,
    name: "Mistakes Screen",
    protected: true,
  },
  {
    path: "/lookup",
    component: LookupScreen,
    name: "Lookup Screen",
    protected: true,
  },
  {
    path: "/contact",
    component: FeedbackScreen,
    name: "Feedback Screen",
    protected: true,
  },
  {
    path: "/stats",
    component: StatisticsScreen,
    name: "Statistics Screen",
    protected: true,
  },
];

export default routes;
