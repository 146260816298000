import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { displayTenseInPortuguese } from "lib/tenseTranslator";

interface Props {
  tense: string;
  display: string;
  pronouns: object;
}

interface VerbListProps {
  verbs: any;
  tense: string;
}

const VerbList = ({ verbs, tense }: VerbListProps) => {
  if (!tense.includes("Imperativo")) {
    return (
      <List>
        {verbs.eu && (
          <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
            <ListItemText>eu: {verbs.eu}</ListItemText>
          </ListItem>
        )}
        {verbs.tu && (
          <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
            <ListItemText>tu: {verbs.tu}</ListItemText>
          </ListItem>
        )}
        {verbs.ele && (
          <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
            <ListItemText>ele/ela/você: {verbs.ele}</ListItemText>
          </ListItem>
        )}
        {verbs.nós && (
          <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
            <ListItemText>nós: {verbs.nós}</ListItemText>
          </ListItem>
        )}
        {verbs.eles && (
          <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
            <ListItemText>eles/elas/vocês: {verbs.eles}</ListItemText>
          </ListItem>
        )}
      </List>
    );
  }
  return (
    <List>
      {verbs.tu && (
        <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
          <ListItemText>tu: {verbs.tu}</ListItemText>
        </ListItem>
      )}
      {verbs.você && (
        <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
          <ListItemText>você: {verbs.ele}</ListItemText>
        </ListItem>
      )}

      {verbs.vocês && (
        <ListItem style={{ paddingTop: 2, paddingBottom: 2 }}>
          <ListItemText>vocês: {verbs.eles}</ListItemText>
        </ListItem>
      )}
    </List>
  );
};

const LookupCard = ({ tense, pronouns, display }: Props) => {
  const pt = displayTenseInPortuguese(tense);

  return (
    <Card key={tense} variant="outlined">
      <CardContent style={{ paddingBottom: 12 }}>
        <Typography variant="h6">{pt}</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {display}
        </Typography>
        <Divider />
        <VerbList verbs={pronouns} tense={tense} />
      </CardContent>
    </Card>
  );
};

export default LookupCard;
